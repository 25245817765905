<template>
    <v-container>
        <v-row>
            <v-col md6 xs12 class="list-title">
                排行榜
            </v-col>
            <v-col md6 xs12 class="list-title">
                替补席
            </v-col>
        </v-row>
        <v-row>
            <v-col md6 xs12 class="box green-box">
                <draggable v-model="leftList" group="people" :move="checkMove" style="height: 500px;">
                    <div v-for="(item, index) in leftList" :key="'left-' + index" class="item" @click="moveToRight(index)">
                        <span class="index">{{ index + 1 }}</span>
                        <img :src="url_prefix + item + '.jpg'" alt="" class="avatar">
                        {{ item }}
                    </div>
                </draggable>
            </v-col>
            <v-col md6 xs12 class="box pink-box">
                <draggable v-model="rightList" group="people" :move="checkMove" style="height: 500px;">
                    <div v-for="(item, index) in rightList" :key="'right-' + index" class="item" @click="moveToLeft(index)">
                        <span class="index">{{ index + 1 }}</span>
                        <img :src="url_prefix + item + '.jpg'" alt="" class="avatar">
                        {{ item }}
                    </div>
                </draggable>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn color="primary" @click="submit">提交</v-btn>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar" color="success">
            提交成功！
            <v-btn text @click="snackbar = false">关闭</v-btn>
        </v-snackbar>
        <v-snackbar v-model="errorSnackbar" color="error">
            {{ errorMessage }}
            <v-btn text @click="errorSnackbar = false">关闭</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import axios from 'axios';

export default {
    name: "Vote",
    components: {
        draggable
    },
    data() {
        return {
            snackbar: false,
            errorSnackbar: false,
            errorMessage: '',
            leftList: [],
            rightList: [],
            dragItem: null,
            dragList: null,
            url_prefix: 'https://cdn-427studio.oss-cn-qingdao.aliyuncs.com/sanguo_header/11/',
        }
    },
    created() {
        // 获取URL中的topic参数
        const topic = this.$route.params.topic;
        if(topic) {
            console.log(topic);
            if(topic.startsWith('water')) {
                this.url_prefix = 'https://cdn-427studio.oss-cn-qingdao.aliyuncs.com/sanguo_header/water/1/'
            }
        }

        // 如果用户已经有token，那么获取用户的投票数据
        const token = localStorage.getItem('token');
        if (token) {
            axios.get('https://qidafang.com/wikiapi/vote', { params: { topic: topic, token: token } }).then(response => {
                this.leftList = response.data.tops ? response.data.tops.split(',') : [];
                this.rightList = response.data.lefts ? response.data.lefts.split(',') : [];

                if (!response.data.tops) {
                    this.rightList = this.rightList.sort(() => Math.random() - 0.5);
                }
            });
        }
    },
    methods: {
        checkMove(event) {
            return event.related.className !== 'non-draggable';
        },
        moveToRight(index) {
            this.rightList.push(this.leftList[index]);
            this.leftList.splice(index, 1);
        },
        moveToLeft(index) {
            this.leftList.push(this.rightList[index]);
            this.rightList.splice(index, 1);
        },
        submit() {
            const token = localStorage.getItem('token');
            const topic = this.$route.params.topic;
            if (token) {
                axios.post('https://qidafang.com/wikiapi/vote', null, {
                    params: {
                        topic: topic,
                        token: token,
                        lefts: this.rightList.join(','),
                        tops: this.leftList.join(',')
                    }
                }).then(() => {
                    this.snackbar = true;
                }).catch((error) => {
                    this.errorMessage = "投票统计阶段无法提交排名";
                    this.errorSnackbar = true;
                });
            }
        }
    }
};
</script>

<style scoped>
.list-title {
    font-size: 20px;
    font-weight: bold;
}
.box {
    max-height: 550px; 
    padding: 20px;
    padding-right: 40px;
    overflow-y: auto;
}
.green-box {
    background-color: #d4edda;  /* 浅绿色 */
}
.pink-box {
    background-color: #f8d7da;  /* 浅粉色 */
}
.item {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    background-color: #d6e9ff;
}
.index {
    font-size: 12px;
    margin-right: 10px;
}
.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}
</style>